var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "mx": "auto",
      "px": ['0', '40px']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center",
      "flex-direction": "row"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": "28px",
      "font-weight": "700",
      "color": "#111111",
      "margin-bottom": "30px",
      "text-align": "left"
    }
  }, [_vm._v(" Detail Klien ")]), _vm.role === 'super_admin' ? _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "1rem"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "variant": "solid",
      "variant-color": "gray",
      "color": "primary.900",
      "rounded": "full",
      "as": "a",
      "href": _vm.exportUrl,
      "download": true,
      "data-something": "haha"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-file-export.svg')
    }
  }), _c('c-text', {
    attrs: {
      "ml": "5px",
      "font-weight": "500",
      "font-size": "16px"
    }
  }, [_vm._v(" Export ")])], 1)], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "margin-bottom": "15px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "600",
      "color": "#008C81",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Data Pribadi ")])], 1), _c('c-flex', {
    attrs: {
      "w": "100%",
      "background-color": "white"
    }
  }, [_c('c-box', {
    attrs: {
      "min-w": ['76px', '152px'],
      "w": ['76px', '152px'],
      "h": ['76px', '152px'],
      "mr": ['15px', '30px'],
      "overflow": "hidden",
      "border-radius": "50%",
      "background-color": "lightGray.900"
    }
  }, [!_vm.isLoadingClient ? _c('c-image', {
    attrs: {
      "object-fit": "cover",
      "src": _vm.getPhotoUser(_vm.client.photoUrl),
      "alt": _vm.client.firstName
    }
  }) : _c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [!_vm.isLoadingClient ? _c('c-heading', {
    attrs: {
      "as": "h3",
      "pos": "relative",
      "mb": [null, '10px'],
      "font-size": ['24px', '36px'],
      "line-height": ['36px', '54px'],
      "font-weight": "700",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.client.firstName) + " " + _vm._s(_vm.client.lastName) + " ")]) : _c('c-box', {
    attrs: {
      "d": "block",
      "w": ['80%', '300px'],
      "h": ['24px', '36px'],
      "mb": "10px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), !_vm.isLoadingClient ? _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '24px'],
      "color": "primary.400",
      "mb": ['5px', '15px']
    }
  }, [_vm._v(" " + _vm._s(_vm.client.id) + " ")]) : _c('c-box', {
    attrs: {
      "d": "block",
      "w": ['100%', '500px'],
      "h": ['14px', '24px'],
      "mb": ['5px', '15px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), !_vm.isLoadingClient ? _c('c-box', {
    attrs: {
      "w": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['12px', '18px'],
      "color": "gray.900",
      "display": "flex",
      "align-items": "center",
      "font-weight": "500"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "inline-block",
      "margin-right": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-telephone.svg'),
      "height": "16",
      "width": "16",
      "fill": "#555555"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.client.phone ? _vm.client.phone : '-') + " ")], 1), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['12px', '18px'],
      "color": "gray.900",
      "display": "flex",
      "align-items": "center",
      "font-weight": "500"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "inline-block",
      "margin-right": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-email.svg'),
      "height": "18",
      "width": "18",
      "fill": "#555555"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.client.email) + " ")], 1)], 1) : _c('c-box', {
    attrs: {
      "w": "100%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-box', {
    attrs: {
      "d": "block",
      "w": ['35%', '200px'],
      "h": ['12px', '18px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": "block",
      "w": ['35%', '200px'],
      "h": ['12px', '18px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1)], 1)], 1), _vm.isLoadingClient ? _c('SkeletonProgramActive') : _vm._e(), _vm.programActive && !_vm.isLoadingClient ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "border-radius": "12px",
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "px": ['12px', '24px'],
      "py": ['12px', '24px'],
      "pos": "relative",
      "my": ['15px', '30px'],
      "flex-dir": ['column', 'row'],
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        _vm.role === 'nutritionist' ? _vm.$router.push("/nutritionist/".concat(_vm.clientId, "/programs/").concat(_vm.programActive.id)) : _vm.$router.push("/admin/clients/".concat(_vm.clientId, "/p/").concat(_vm.programActive.id));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "mr": ['0', '30px'],
      "flex-shrink": "0"
    }
  }, [_c('c-image', {
    attrs: {
      "w": ['100%', '150px'],
      "h": ['150px', '100%'],
      "object-fit": "cover",
      "border-radius": "8px",
      "src": _vm.programActive.product.photoUrl,
      "alt": "program"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "gap": ['4px', '8px']
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-size": ['16px', '18px'],
      "line-height": "27px",
      "font-weight": "600",
      "color": "#008C81"
    }
  }, [_vm._v(" Program ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.programActive.name) + " (" + _vm._s(_vm.programActive.programService) + ") ")]), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-size": ['16px', '18px'],
      "line-height": "27px",
      "font-weight": "600",
      "color": "#008C81"
    }
  }, [_vm._v(" Join Date - End Date ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.programActive.startAt, 'DD MMMM YYYY')) + " - " + _vm._s(_vm._f("formatDate")(_vm.programActive.endAt, 'DD MMMM YYYY')) + " ")]), _c('c-heading', {
    attrs: {
      "as": "h3",
      "font-size": ['16px', '18px'],
      "line-height": "27px",
      "font-weight": "600",
      "color": "#008C81"
    }
  }, [_vm._v(" Durasi ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.programActive.duration) + " Hari ")])], 1), _c('c-flex', {
    attrs: {
      "flex-shrink": "0",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "flex-end"
    }
  }, [_c('c-button', {
    attrs: {
      "min-w": "32px",
      "min-h": "32px",
      "p": "8px 16px",
      "border-radius": "20px",
      "color": _vm.programActive.status === 'pending' ? '#DA6D06' : _vm.programActive.status == 'done' ? '#008C81' : _vm.programActive.status == 'active' ? '#0C72E0' : '#F2F2F2',
      "bg-color": _vm.programActive.status === 'pending' ? '#FDEDCB' : _vm.programActive.status == 'done' ? '#C7F9E3' : _vm.programActive.status == 'active' ? '#CDEDFC' : '#888888',
      "border-width": "2px",
      "border-color": _vm.programActive.status === 'pending' ? '#DA6D06' : _vm.programActive.status == 'done' ? '#008C81' : _vm.programActive.status == 'active' ? '#0C72E0' : '#F2F2F2',
      "border-style": "solid",
      "align-self": "flex-end",
      "mb": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("programStatus")(_vm.programActive.status)) + " ")]), _c('c-text', {
    attrs: {
      "color": "#111",
      "font-size": ['16px', '18px'],
      "font-weight": "500"
    }
  }, [_vm._v(" Counter Day: " + _vm._s(_vm.programActive.counterDay) + " ")])], 1)], 1) : _vm._e(), _vm.role === 'nutritionist' ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "border-radius": "12px",
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "px": ['13px', '26px'],
      "py": ['13px', '26px'],
      "pos": "relative",
      "my": ['15px', '30px']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "64px",
      "height": "64px",
      "mr": ['15px', '30px']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "w": "64px",
      "h": "64px",
      "overflow": "hidden",
      "border-radius": "100%",
      "background-color": "primary.400"
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/mealplan_icon.webp?updatedAt=1688397449063",
      "alt": "icon"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "margin-bottom": "12px",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "700",
      "color": "black.900"
    }
  }, [_vm._v(" Rencana Makan ")]), _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": "14px",
      "color": "darkGray.900"
    }
  }, [_vm._v(" Last Update: - ")])], 1), false ? _c('c-button', {
    attrs: {
      "right-icon": "chevron-right",
      "font-size": "18px",
      "font-weight": "500",
      "padding": "0",
      "background-color": "transparent",
      "color": "primary.400",
      "pos": "absolute",
      "top": "50%",
      "right": "30px",
      "transform": "translateY(-50%)",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-gizi',
        params: _vm.$route.params
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "w": "100%",
      "margin-bottom": "15px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "600",
      "color": "#008C81",
      "margin-top": "30px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" History Program ")])], 1), _vm.isLoadingProgram ? _c('SkeletonCardProgram') : _vm._e(), !_vm.isLoadingProgram && _vm.programs.length > 0 ? _c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "6"
    }
  }, _vm._l(_vm.programs, function (program) {
    return _c('c-box', {
      key: program.id,
      attrs: {
        "w": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%",
        "as": "router-link",
        "to": _vm.role === 'nutritionist' ? "/nutritionist/".concat(_vm.clientId, "/programs/").concat(program.id) : "/admin/clients/".concat(_vm.clientId, "/p/").concat(program.id)
      }
    }, [_c('c-flex', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: '#F2F2F2'
          }
        },
        expression: "{\n            ':hover': {\n              bg: '#F2F2F2',\n            },\n          }"
      }],
      attrs: {
        "w": "100%",
        "background-color": "white",
        "border-radius": "12px",
        "border": "1px solid #f2f2f2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "px": "16px",
        "py": "16px",
        "pos": "relative",
        "margi-bottom": "16px",
        "cursor": "pointer"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%"
      }
    }, [_c('c-heading', {
      attrs: {
        "as": "h3",
        "margin-bottom": "0px",
        "font-size": "18px",
        "line-height": "27px",
        "font-weight": "700",
        "color": "black.900"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(program.name)) + " ")]), _c('c-text', {
      attrs: {
        "font-family": "Roboto",
        "font-weight": "500%",
        "font-size": "18px",
        "color": "primary.400",
        "margin-bottom": "10px"
      }
    }, [_vm._v(" Berakhir pada ")]), _c('c-text', {
      attrs: {
        "font-family": "Roboto",
        "font-size": "14px",
        "color": "darkGray.900"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(program.endAt, 'DD MMMM YYYY')) + " ")])], 1), _c('c-button', {
      attrs: {
        "min-w": "32px",
        "min-h": "32px",
        "p": "8px 16px",
        "border-radius": "20px",
        "color": program.status === 'pending' ? '#DA6D06' : program.status == 'done' ? '#008C81' : program.status == 'active' ? '#0C72E0' : '#F2F2F2',
        "bg-color": program.status === 'pending' ? '#FDEDCB' : program.status == 'done' ? '#C7F9E3' : program.status == 'active' ? '#CDEDFC' : '#888888',
        "border-width": "2px",
        "border-color": program.status === 'pending' ? '#DA6D06' : program.status == 'done' ? '#008C81' : program.status == 'active' ? '#0C72E0' : '#F2F2F2',
        "border-style": "solid",
        "align-self": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("programStatus")(program.status)) + " ")])], 1)], 1)], 1);
  }), 1) : _vm._e(), _c('c-box', {
    attrs: {
      "w": "100%",
      "margin-bottom": "15px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "600",
      "color": "#008C81",
      "margin-top": "30px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" History Formulir Gizi ")])], 1), _vm.isLoadingQuestionnaire ? _c('SkeletonCardQuestionnaire') : _vm._e(), !_vm.isLoadingQuestionnaire && _vm.questionnaires.length > 0 ? _c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "6"
    }
  }, _vm._l(_vm.questionnaires, function (questionnaire) {
    return _c('c-box', {
      key: questionnaire.id,
      attrs: {
        "w": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%",
        "as": "router-link",
        "to": _vm.role === 'nutritionist' ? "/nutritionist/".concat(_vm.clientId, "/quizionary/").concat(questionnaire.programId) : "/admin/clients/".concat(_vm.clientId, "/p/").concat(questionnaire.programId, "/quizionary")
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "background-color": "white",
        "border-radius": "12px",
        "border": "1px solid #f2f2f2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "px": "16px",
        "py": "16px",
        "pos": "relative",
        "margi-bottom": "16px"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%"
      }
    }, [_c('c-heading', {
      attrs: {
        "as": "h3",
        "margin-bottom": "0px",
        "font-size": "18px",
        "line-height": "27px",
        "font-weight": "700",
        "color": "black.900"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(questionnaire.program.name)) + " ")]), _c('c-text', {
      attrs: {
        "font-family": "Roboto",
        "font-size": "14px",
        "color": "darkGray.900"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(questionnaire.completedAt, 'DD MMMM YYYY')) + " ")])], 1), _c('c-icon', {
      attrs: {
        "name": "chevron-right",
        "size": "24px",
        "color": "#008C81",
        "align-self": "center"
      }
    })], 1)], 1)], 1);
  }), 1) : _vm._e(), _vm.role != 'nutritionist' ? _c('c-box', {
    attrs: {
      "w": "100%",
      "margin-bottom": "15px",
      "border-bottom": "1px solid #C4C4C4"
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['18px', '20px'],
      "font-weight": "600",
      "color": "#008C81",
      "margin-top": "30px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" History Pembayaran ")])], 1) : _vm._e(), _vm.isLoadingTransaction ? _c('SkeletonCardTransaction') : _vm._e(), !_vm.isLoadingTransaction && _vm.transactions.length > 0 ? _c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "6"
    }
  }, _vm._l(_vm.transactions, function (transaction) {
    var _transaction$paymentM, _transaction$paymentM2;

    return _c('c-box', {
      key: transaction.id,
      attrs: {
        "w": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "w": "100%",
        "background-color": "white",
        "border-radius": "12px",
        "border": "1px solid #f2f2f2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "px": "16px",
        "py": "16px",
        "pos": "relative",
        "margi-bottom": "16px"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "justify": "space-between",
        "align": "center",
        "mb": "4px"
      }
    }, [_c('c-heading', {
      attrs: {
        "as": "h3",
        "margin-bottom": "0px",
        "font-size": "18px",
        "line-height": "27px",
        "font-weight": "700",
        "color": "black.900"
      }
    }, [_vm._v(" " + _vm._s(transaction.invoiceNumber) + " ")]), _c('c-text', {
      attrs: {
        "font-family": "Roboto",
        "font-size": "14px",
        "color": _vm.transactionStatus(transaction.status).color
      }
    }, [_vm._v(" " + _vm._s(_vm.transactionStatus(transaction.status).text) + " ")])], 1), _c('c-text', {
      attrs: {
        "font-size": "20px",
        "line-height": "150%",
        "font-weight": "700",
        "color": "primary.400",
        "margin-bottom": "4px"
      }
    }, [_vm._v(" " + _vm._s(transaction.productServiceName) + " ")]), _c('c-accordion', {
      attrs: {
        "allow-toggle": true,
        "default-index": [],
        "mb": "8px"
      }
    }, [_c('c-accordion-item', {
      attrs: {
        "border": "none"
      }
    }, [_c('c-accordion-header', {
      attrs: {
        "bg": "#C7F9E3",
        "py": "6px"
      }
    }, [_c('c-box', {
      attrs: {
        "flex": "1",
        "text-align": "left",
        "color": "primary.400",
        "font-weight": "700",
        "font-size": "20px"
      }
    }, [_vm._v(" Rp." + _vm._s(Number(transaction.total).toLocaleString("id")) + ",- ")]), _c('c-accordion-icon')], 1), _c('c-accordion-panel', {
      attrs: {
        "px": "1rem",
        "pb": "0",
        "pt": "6px"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-dir": "row",
        "justify": "space-between",
        "align": "center",
        "mb": "4px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" Diskon ")]), _c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" -Rp" + _vm._s(Number(transaction.totalDiscount).toLocaleString("id")) + ",- ")])], 1), _c('c-flex', {
      attrs: {
        "flex-dir": "row",
        "justify": "space-between",
        "align": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" Metode ")]), _c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((_transaction$paymentM = transaction === null || transaction === void 0 ? void 0 : (_transaction$paymentM2 = transaction.paymentMethod) === null || _transaction$paymentM2 === void 0 ? void 0 : _transaction$paymentM2.paymentMethodName) !== null && _transaction$paymentM !== void 0 ? _transaction$paymentM : '-') + " ")])], 1)], 1)], 1)], 1), transaction.status == 'done' || transaction.status == 'active' ? _c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "400",
        "color": "#333"
      }
    }, [_vm._v(" Dibayarkan pada " + _vm._s(_vm._f("formatDate")(transaction.transactionTime, 'DD MMMM YYYY, HH:mm')) + " WIB ")]) : _vm._e()], 1)], 1)], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }