<template>
  <c-box
    mx="auto"
    :px="['0', '40px']"
  >
    <c-flex
      justify-content="space-between"
      align-items="center"
      flex-direction="row"
    >
      <c-heading
        font-family="Roboto"
        font-size="28px"
        font-weight="700"
        color="#111111"
        margin-bottom="30px"
        text-align="left"
      >
        Detail Klien
      </c-heading>
      <c-flex
        v-if="role === 'super_admin'"
        align-items="center"
        gap="1rem"
      >
        <c-button
          type="button"
          variant="solid"
          variant-color="gray"
          color="primary.900"
          rounded="full"
          as="a"
          :href="exportUrl"
          :download="true"
          data-something="haha"
        >
          <c-image :src="require('@/assets/icon-file-export.svg')" />
          <c-text
            ml="5px"
            font-weight="500"
            font-size="16px"
          >
            Export
          </c-text>
        </c-button>
        <!-- <c-button
          type="router-link"
          variant="solid"
          variant-color="primary"
          color="white"
          rounded="full"
          ml="10px"
          :to="`/admin/clients/${clientId}`"
        >
          <c-image :src="require('@/assets/icon-pencil.svg')" />
          <c-text ml="5px" font-weight="500" font-size="16px">Edit</c-text>
        </c-button> -->
      </c-flex>
    </c-flex>

    <!-- Private Data -->
    <c-box
      w="100%"
      margin-bottom="15px"
      border-bottom="1px solid #C4C4C4"
    >
      <c-heading
        font-family="Roboto"
        :font-size="['18px', '20px']"
        font-weight="600"
        color="#008C81"
        margin-bottom="8px"
      >
        Data Pribadi
      </c-heading>
    </c-box>

    <c-flex
      w="100%"
      background-color="white"
    >
      <c-box
        :min-w="['76px', '152px']"
        :w="['76px', '152px']"
        :h="['76px', '152px']"
        :mr="['15px', '30px']"
        overflow="hidden"
        border-radius="50%"
        background-color="lightGray.900"
      >
        <c-image
          v-if="!isLoadingClient"
          object-fit="cover"
          :src="getPhotoUser(client.photoUrl)"
          :alt="client.firstName"
        />
        <vue-skeleton-loader
          v-else
          type="rect"
          width="100%"
          height="100%"
          rounded
          animation="fade"
        />
      </c-box>
      <c-box w="100%">
        <c-heading
          v-if="!isLoadingClient"
          as="h3"
          pos="relative"
          :mb="[null, '10px']"
          :font-size="['24px', '36px']"
          :line-height="['36px', '54px']"
          font-weight="700"
          color="#008C81"
        >
          {{ client.firstName }} {{ client.lastName }}
        </c-heading>
        <c-box
          v-else
          d="block"
          :w="['80%', '300px']"
          :h="['24px', '36px']"
          mb="10px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>

        <c-text
          v-if="!isLoadingClient"
          font-family="Roboto"
          :font-size="['14px', '24px']"
          color="primary.400"
          :mb="['5px', '15px']"
        >
          {{ client.id }}
        </c-text>
        <c-box
          v-else
          d="block"
          :w="['100%', '500px']"
          :h="['14px', '24px']"
          :mb="['5px', '15px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>

        <c-box
          v-if="!isLoadingClient"
          w="100%"
          display="flex"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-family="Roboto"
            :font-size="['12px', '18px']"
            color="gray.900"
            display="flex"
            align-items="center"
            font-weight="500"
          >
            <c-box
              display="inline-block"
              margin-right="8px"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-telephone.svg')"
                height="16"
                width="16"
                fill="#555555"
              />
            </c-box>
            {{ client.phone ? client.phone : '-' }}
          </c-text>
          <c-text
            font-family="Roboto"
            :font-size="['12px', '18px']"
            color="gray.900"
            display="flex"
            align-items="center"
            font-weight="500"
          >
            <c-box
              display="inline-block"
              margin-right="8px"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-email.svg')"
                height="18"
                width="18"
                fill="#555555"
              />
            </c-box>
            {{ client.email }}
          </c-text>
        </c-box>
        <c-box
          v-else
          w="100%"
          display="flex"
          align-items="center"
          justify-content="space-between"
        >
          <c-box
            d="block"
            :w="['35%', '200px']"
            :h="['12px', '18px']"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              rounded
              animation="fade"
            />
          </c-box>
          <c-box
            d="block"
            :w="['35%', '200px']"
            :h="['12px', '18px']"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              rounded
              animation="fade"
            />
          </c-box>
        </c-box>
      </c-box>
    </c-flex>

    <!-- Program Active -->
    <SkeletonProgramActive v-if="isLoadingClient" />
    <c-flex
      v-if="programActive && !isLoadingClient"
      w="100%"
      background-color="white"
      border-radius="12px"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      :px="['12px', '24px']"
      :py="['12px', '24px']"
      pos="relative"
      :my="['15px', '30px']"
      :flex-dir="['column', 'row']"
      cursor="pointer"
      @click="role === 'nutritionist' ? $router.push(`/nutritionist/${clientId}/programs/${programActive.id}`) : $router.push(`/admin/clients/${clientId}/p/${programActive.id}`)"
    >
      <c-box
        :mr="['0', '30px']"
        flex-shrink="0"
      >
        <c-image
          :w="['100%', '150px']"
          :h="['150px', '100%']"
          object-fit="cover"
          border-radius="8px"
          :src="programActive.product.photoUrl"
          alt="program"
        />
      </c-box>
      <c-flex
        flex-grow="1"
        flex-direction="column"
        :gap="['4px', '8px']"
      >
        <c-heading
          as="h3"
          :font-size="['16px', '18px']"
          line-height="27px"
          font-weight="600"
          color="#008C81"
        >
          Program
        </c-heading>
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          color="#333333"
        >
          {{ programActive.name }} ({{ programActive.programService }})
        </c-text>
        <c-heading
          as="h3"
          :font-size="['16px', '18px']"
          line-height="27px"
          font-weight="600"
          color="#008C81"
        >
          Join Date - End Date
        </c-heading>
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          color="#333333"
        >
          {{ programActive.startAt | formatDate('DD MMMM YYYY') }} -
          {{ programActive.endAt | formatDate('DD MMMM YYYY') }}
        </c-text>
        <c-heading
          as="h3"
          :font-size="['16px', '18px']"
          line-height="27px"
          font-weight="600"
          color="#008C81"
        >
          Durasi
        </c-heading>
        <c-text
          font-family="Roboto"
          :font-size="['14px', '16px']"
          color="#333333"
        >
          {{ programActive.duration }} Hari
        </c-text>
      </c-flex>
      <c-flex
        flex-shrink="0"
        flex-direction="column"
        justify-content="space-between"
        align-items="flex-end"
      >
        <c-button
          min-w="32px"
          min-h="32px"
          p="8px 16px"
          border-radius="20px"
          :color="
            programActive.status === 'pending'
              ? '#DA6D06'
              : programActive.status == 'done'
                ? '#008C81'
                : programActive.status == 'active'
                  ? '#0C72E0'
                  : '#F2F2F2'
          "
          :bg-color="
            programActive.status === 'pending'
              ? '#FDEDCB'
              : programActive.status == 'done'
                ? '#C7F9E3'
                : programActive.status == 'active'
                  ? '#CDEDFC'
                  : '#888888'
          "
          border-width="2px"
          :border-color="
            programActive.status === 'pending'
              ? '#DA6D06'
              : programActive.status == 'done'
                ? '#008C81'
                : programActive.status == 'active'
                  ? '#0C72E0'
                  : '#F2F2F2'
          "
          border-style="solid"
          align-self="flex-end"
          mb="8px"
        >
          {{ programActive.status | programStatus }}
        </c-button>
        <c-text
          color="#111"
          :font-size="['16px', '18px']"
          font-weight="500"
        >
          Counter Day: {{ programActive.counterDay }}
        </c-text>
      </c-flex>
    </c-flex>

    <!-- Meal plan -->
    <c-flex
      v-if="role === 'nutritionist'"
      w="100%"
      background-color="white"
      border-radius="12px"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      :px="['13px', '26px']"
      :py="['13px', '26px']"
      pos="relative"
      :my="['15px', '30px']"
    >
      <c-box
        width="64px"
        height="64px"
        :mr="['15px', '30px']"
      >
        <c-flex
          justify-content="center"
          align-items="center"
          w="64px"
          h="64px"
          overflow="hidden"
          border-radius="100%"
          background-color="primary.400"
        >
          <c-image
            src="https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/mealplan_icon.webp?updatedAt=1688397449063"
            alt="icon"
          />
        </c-flex>
      </c-box>
      <c-box w="100%">
        <c-heading
          as="h3"
          margin-bottom="12px"
          font-size="18px"
          line-height="27px"
          font-weight="700"
          color="black.900"
        >
          Rencana Makan
        </c-heading>
        <c-text
          font-family="Roboto"
          font-size="14px"
          color="darkGray.900"
        >
          Last Update: -
        </c-text>
      </c-box>
      <c-button
        v-if="false"
        right-icon="chevron-right"
        font-size="18px"
        font-weight="500"
        padding="0"
        background-color="transparent"
        color="primary.400"
        pos="absolute"
        top="50%"
        right="30px"
        transform="translateY(-50%)"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi', params: $route.params }"
      >
        Edit
      </c-button>
    </c-flex>

    <!-- History Program -->
    <c-box
      w="100%"
      margin-bottom="15px"
      border-bottom="1px solid #C4C4C4"
    >
      <c-heading
        font-family="Roboto"
        :font-size="['18px', '20px']"
        font-weight="600"
        color="#008C81"
        margin-top="30px"
        margin-bottom="8px"
      >
        History Program
      </c-heading>
    </c-box>
    <SkeletonCardProgram v-if="isLoadingProgram" />
    <c-grid
      v-if="!isLoadingProgram && programs.length > 0"
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="6"
    >
      <c-box
        v-for="program in programs"
        :key="program.id"
        w="100%"
      >
        <c-box 
          w="100%" 
          as="router-link" 
          :to="role === 'nutritionist' ? (`/nutritionist/${clientId}/programs/${program.id}`) : (`/admin/clients/${clientId}/p/${program.id}`)"
        >
          <c-flex
            v-chakra="{
              ':hover': {
                bg: '#F2F2F2',
              },
            }"
            w="100%"
            background-color="white"
            border-radius="12px"
            border="1px solid #f2f2f2"
            box-shadow="0px 5px 30px 0px #0000000D"
            px="16px"
            py="16px"
            pos="relative"
            margi-bottom="16px"
            cursor="pointer"
          >
            <c-box w="100%">
              <c-heading
                as="h3"
                margin-bottom="0px"
                font-size="18px"
                line-height="27px"
                font-weight="700"
                color="black.900"
              >
                {{ program.name | capitalize }}
              </c-heading>
              <c-text
                font-family="Roboto"
                font-weight="500%"
                font-size="18px"
                color="primary.400"
                margin-bottom="10px"
              >
                Berakhir pada
              </c-text>
              <c-text
                font-family="Roboto"
                font-size="14px"
                color="darkGray.900"
              >
                {{ program.endAt | formatDate('DD MMMM YYYY') }}
              </c-text>
            </c-box>
            <c-button
              min-w="32px"
              min-h="32px"
              p="8px 16px"
              border-radius="20px"
              :color="
                program.status === 'pending'
                  ? '#DA6D06'
                  : program.status == 'done'
                    ? '#008C81' 
                    : program.status == 'active'
                      ? '#0C72E0'
                      : '#F2F2F2'
              "
              :bg-color="
                program.status === 'pending'
                  ? '#FDEDCB'
                  : program.status == 'done'
                    ? '#C7F9E3'
                    : program.status == 'active'
                      ? '#CDEDFC'
                      : '#888888'
              "
              border-width="2px"
              :border-color="
                program.status === 'pending'
                  ? '#DA6D06'
                  : program.status == 'done'
                    ? '#008C81'
                    : program.status == 'active'
                      ? '#0C72E0'
                      : '#F2F2F2'
              "
              border-style="solid"
              align-self="center"
            >
              {{ program.status | programStatus }}
            </c-button>
          </c-flex>
        </c-box>
      </c-box>
    </c-grid>

    <!-- History Questionnaire -->
    <c-box
      w="100%"
      margin-bottom="15px"
      border-bottom="1px solid #C4C4C4"
    >
      <c-heading
        font-family="Roboto"
        :font-size="['18px', '20px']"
        font-weight="600"
        color="#008C81"
        margin-top="30px"
        margin-bottom="8px"
      >
        History Formulir Gizi
      </c-heading>
    </c-box>
    <SkeletonCardQuestionnaire v-if="isLoadingQuestionnaire" />
    <c-grid
      v-if="!isLoadingQuestionnaire && questionnaires.length > 0"
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="6"
    >
      <c-box
        v-for="questionnaire in questionnaires"
        :key="questionnaire.id"
        w="100%"
      >
        <c-box
          w="100%"
          as="router-link"
          :to="role === 'nutritionist' ? (`/nutritionist/${clientId}/quizionary/${questionnaire.programId}`) : (`/admin/clients/${clientId}/p/${questionnaire.programId}/quizionary`)"
        >
          <c-flex
            w="100%"
            background-color="white"
            border-radius="12px"
            border="1px solid #f2f2f2"
            box-shadow="0px 5px 30px 0px #0000000D"
            px="16px"
            py="16px"
            pos="relative"
            margi-bottom="16px"
          >
            <c-box w="100%">
              <c-heading
                as="h3"
                margin-bottom="0px"
                font-size="18px"
                line-height="27px"
                font-weight="700"
                color="black.900"
              >
                {{ questionnaire.program.name | capitalize }}
              </c-heading>
              <c-text
                font-family="Roboto"
                font-size="14px"
                color="darkGray.900"
              >
                {{ questionnaire.completedAt | formatDate('DD MMMM YYYY') }}
              </c-text>
            </c-box>
            <c-icon
              name="chevron-right"
              size="24px"
              color="#008C81"
              align-self="center"
            />
          </c-flex>
        </c-box>
      </c-box>
    </c-grid>

    <!-- History Transaction -->
    <c-box
      v-if="role != 'nutritionist'"
      w="100%"
      margin-bottom="15px"
      border-bottom="1px solid #C4C4C4"
    >
      <c-heading
        font-family="Roboto"
        :font-size="['18px', '20px']"
        font-weight="600"
        color="#008C81"
        margin-top="30px"
        margin-bottom="8px"
      >
        History Pembayaran
      </c-heading>
    </c-box>
    <SkeletonCardTransaction v-if="isLoadingTransaction" />
    <c-grid
      v-if="!isLoadingTransaction && transactions.length > 0"
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="6"
    >
      <c-box
        v-for="transaction in transactions"
        :key="transaction.id"
        w="100%"
      >
        <c-box
          w="100%"
        >
          <c-flex
            flex-direction="column"
            w="100%"
            background-color="white"
            border-radius="12px"
            border="1px solid #f2f2f2"
            box-shadow="0px 5px 30px 0px #0000000D"
            px="16px"
            py="16px"
            pos="relative"
            margi-bottom="16px"
          >
            <c-flex
              w="100%"
              justify="space-between"
              align="center"
              mb="4px"
            >
              <c-heading
                as="h3"
                margin-bottom="0px"
                font-size="18px"
                line-height="27px"
                font-weight="700"
                color="black.900"
              >
                {{ transaction.invoiceNumber }}
              </c-heading>
              <c-text
                font-family="Roboto"
                font-size="14px"
                :color="transactionStatus(transaction.status).color"
              >
                {{ transactionStatus(transaction.status).text }}
              </c-text>
            </c-flex>
            <c-text
              font-size="20px"
              line-height="150%"
              font-weight="700"
              color="primary.400"
              margin-bottom="4px"
            >
              {{ transaction.productServiceName }}
            </c-text>
            <c-accordion
              :allow-toggle="true"
              :default-index="[]"
              mb="8px"
            >
              <c-accordion-item border="none">
                <c-accordion-header
                  bg="#C7F9E3"
                  py="6px"
                >
                  <c-box
                    flex="1"
                    text-align="left"
                    color="primary.400"
                    font-weight="700"
                    font-size="20px"
                  >
                    Rp.{{ Number(transaction.total).toLocaleString("id") }},-
                  </c-box>
                  <c-accordion-icon />
                </c-accordion-header>
                <c-accordion-panel
                  px="1rem"
                  pb="0"
                  pt="6px"
                >
                  <c-flex
                    flex-dir="row"
                    justify="space-between"
                    align="center"
                    mb="4px"
                  >
                    <c-text
                      font-size="14px"
                      font-weight="400"
                      color="primary.400"
                    >
                      Diskon
                    </c-text>
                    <c-text
                      font-size="14px"
                      font-weight="400"
                      color="primary.400"
                    >
                      -Rp{{ Number(transaction.totalDiscount).toLocaleString("id") }},-
                    </c-text>
                  </c-flex>
                  <c-flex
                    flex-dir="row"
                    justify="space-between"
                    align="center"
                  >
                    <c-text
                      font-size="14px"
                      font-weight="400"
                      color="primary.400"
                    >
                      Metode
                    </c-text>
                    <c-text
                      font-size="14px"
                      font-weight="400"
                      color="primary.400"
                    >
                      {{ transaction?.paymentMethod?.paymentMethodName ?? '-' }}
                    </c-text>
                  </c-flex>
                </c-accordion-panel>
              </c-accordion-item>
            </c-accordion>
            <c-text
              v-if="transaction.status == 'done' || transaction.status == 'active'"
              font-size="14px"
              font-weight="400"
              color="#333"
            >
              Dibayarkan pada {{ transaction.transactionTime | formatDate('DD MMMM YYYY, HH:mm') }} WIB
            </c-text>
          </c-flex>
        </c-box>
      </c-box>
    </c-grid>
  </c-box>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'
import generalMixin from '@/utils/general-mixins'
import VueSkeletonLoader from 'skeleton-loader-vue'  
import SkeletonProgramActive from '@/components/skeletons/skeleton-program-active.vue'
import SkeletonCardProgram from '@/components/skeletons/skeleton-card-program.vue'
import SkeletonCardQuestionnaire from '@/components/skeletons/skeleton-card-questionnaire.vue'
import SkeletonCardTransaction from '@/components/skeletons/skeleton-card-transaction.vue'

export default {
  name: 'ManagementClientDetailAlt',
  components: {
    VueSkeletonLoader,
    SkeletonProgramActive,
    SkeletonCardProgram,
    SkeletonCardQuestionnaire,
    SkeletonCardTransaction,
  },
  filters: {
    capitalize(value) {
      value = value.split('_').join(' ')
      value = _.capitalize(value)
      return value
    },
    programStatus(value) {
      if (value === 'active') {
        return 'Aktif'
      } else if (value === 'pending') {
        return 'Pending'
      } else if (value === 'done') {
        return 'Selesai'
      }
    },
  },
  mixins: [generalMixin],
  props: {
    role: {
      type: String,
      required: true,
      default: 'nutritionist',
    },
    exportUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      client: {},
      programActive: null,
      programs: [],
      questionnaires: [],
      transactions: [],
      isLoadingClient: false,
      isLoadingProgram: false,
      isLoadingQuestionnaire: false,
      isLoadingTransaction: false,
    }
  },
  computed: {
    
  },
  watch: {
    clientId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initClient(val)
          this.initPrograms(val)
          this.initQuestionnaire(val)
          if (this.role != 'nutritionist') {
            this.initTransaction(val)
          }
          // this.$store.dispatch('suManagementClient/getClientById', clientId)
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getClientDetail: 'clients/getClientDetail',
      getClientPrograms: 'clients/getClientPrograms',
      getClientQuestionnaire: 'clients/getClientQuestionnaire',
      getClientTransaction: 'clients/getClientTransaction',
    }),
    initClient(clientId) {
      this.isLoadingClient = true
      this.getClientDetail(clientId)
        .then((res) => {
          this.client = res.data.user
          if (res.data?.program?.status == 'active') {
            this.programActive = res.data.program
          }
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data?.message ?? 'When get data client.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingClient = false
        })
    },
    initPrograms(clientId) {
      this.isLoadingProgram = true
      this.getClientPrograms(clientId)
        .then((res) => {
          this.programs = res.data
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data?.message ?? 'When get data programs.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingProgram = false
        })
    },
    initQuestionnaire(clientId) {
      this.isLoadingQuestionnaire = true
      this.getClientQuestionnaire(clientId)
        .then((res) => {
          this.questionnaires = res.data
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data?.message ?? 'When get data questionnaire',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingQuestionnaire = false
        })
    },
    initTransaction(clientId) {
      this.isLoadingTransaction = true
      this.getClientTransaction(clientId)
        .then((res) => {
          this.transactions = res.data
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data?.message ?? 'When get data transactions',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isLoadingTransaction = false
        })
    },
    transactionStatus(value) {
      switch (value) {
        case 'active':
          return {
            text: 'Aktif',
            color: '#0C72E0',
          }
        case 'pending':
          return {
            text: 'Pending',
            color: '#DA6D06',
          }
        case 'done':
          return {
            text: 'Selesai',
            color: '#008C81',
          }
        default: {
          return {
            text: 'Gagal',
            color: '#D32737',
          }
        }
      }
    },
  },
}
</script>
